import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Paper,
  TableContainer,
  LinearProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@mui/material'
import { RefreshOutlined, Add } from '@mui/icons-material'
import PowerBIReportsTableRow from '@tabeeb/modules/powerBIReports/components/PowerBIReportsTableRow'
import { getPowerBIReportsState } from '@tabeeb/modules/powerBIReports/selectors'
import {
  loadPowerBIReports,
  openAddPowerBIReportModal,
  setPowerBIReportsFilters,
} from '@tabeeb/modules/powerBIReports/actions'
import { PageHeader, Pagination } from '@tabeeb/uikit'
import { usePagination } from '@tabeeb/shared/utils/hooks'
import { AddPowerBIReportModal } from '../Modals'

const PowerBIReportsTable = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadPowerBIReports())
  }, [dispatch])

  const { list: reports, totalCount, isLoading, filters } = useSelector(getPowerBIReportsState)

  const {
    current: currentPage,
    pages,
    size,
    onPageNumberChange,
    onPageSizeChange,
  } = usePagination({ total: totalCount, pageSize: filters.pageSize, initialPage: filters.pageNumber })

  const handlePageSizeChange = useCallback(
    (pageSize) => {
      dispatch(setPowerBIReportsFilters({ pageSize, pageNumber: 1 }))
      onPageSizeChange(pageSize)
    },
    [dispatch, onPageSizeChange]
  )

  const handlePageNumberChange = useCallback(
    (pageNumber) => {
      dispatch(setPowerBIReportsFilters({ pageNumber }))
      onPageNumberChange(pageNumber)
    },
    [dispatch, onPageNumberChange]
  )

  const onRefresh = useCallback(() => {
    dispatch(loadPowerBIReports())
  }, [dispatch])

  const onAddReport = useCallback(() => {
    dispatch(openAddPowerBIReportModal())
  }, [dispatch])

  return (
    <>
      <Paper sx={{ p: 2, pt: 1 }}>
        <PageHeader
          title='PowerBI reports'
          actions={
            <>
              <Button
                disabled={isLoading}
                startIcon={<RefreshOutlined color={isLoading ? 'disabled' : 'primary'} />}
                variant='outlined'
                onClick={onRefresh}
              >
                Refresh
              </Button>
              <Button
                disabled={isLoading}
                startIcon={<Add color={isLoading ? 'disabled' : 'primary'} />}
                variant='outlined'
                onClick={onAddReport}
              >
                Add
              </Button>
            </>
          }
        />
        <TableContainer
          sx={{ position: 'relative', height: 'calc(100vh - 200px)' }}
          variant='outlined'
          component={Paper}
        >
          {isLoading && <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 3 }} />}
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow>
                <TableCell align='center' width={60}>
                  #
                </TableCell>
                <TableCell align='left' width={500}>
                  Report name
                </TableCell>
                <TableCell align='left' width={250}>
                  Owner
                </TableCell>
                <TableCell align='left' width={200}>
                  Security model
                </TableCell>
                <TableCell align='left' width={500}>
                  Provider name
                </TableCell>
                <TableCell width={100} />
              </TableRow>
            </TableHead>
            <TableBody>
              {reports.length === 0 && (
                <TableRow>
                  <TableCell colSpan={8} align='center'>
                    <Typography variant='subtitle1'>{isLoading ? 'Loading reports' : 'No reports found'}</Typography>
                  </TableCell>
                </TableRow>
              )}
              {reports.map((report, index) => (
                <PowerBIReportsTableRow key={report.Id} report={report} index={index} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          current={currentPage}
          pages={pages}
          pageSize={size}
          onPageNumberChange={handlePageNumberChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Paper>
      <AddPowerBIReportModal />
    </>
  )
}

export default PowerBIReportsTable
