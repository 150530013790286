import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { Backdrop } from '@material-ui/core'
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'

import { tenantAuthenticateRetry, resetTenantAuthenticate, tenantAuthenticate } from '@tabeeb/modules/account/actions'
import { getIsTenantAccountUnexpectedError, getIsTenantAuthFlowCompleted } from '@tabeeb/modules/account/selectors'

const TenantAuthenticate = ({ children }) => {
  const dispatch = useDispatch()
  const isTenantAuthFlowCompleted = useSelector(getIsTenantAuthFlowCompleted)
  const isTenantAccountUnexpectedError = useSelector(getIsTenantAccountUnexpectedError)

  const shouldRenderApp = isTenantAuthFlowCompleted && !isTenantAccountUnexpectedError

  const handleRetryButtonClick = useCallback(() => {
    dispatch(tenantAuthenticateRetry())
  }, [dispatch])

  useEffect(() => {
    dispatch(tenantAuthenticate())
    return () => {
      dispatch(resetTenantAuthenticate())
    }
  }, [dispatch])

  return (
    <>
      <Backdrop style={{ zIndex: 9999, backgroundColor: 'inherit' }} open={!shouldRenderApp} transitionDuration={0}>
        {isTenantAccountUnexpectedError ? (
          <Stack>
            <Box sx={{ mx: 'auto' }}>
              <ErrorIcon sx={{ fontSize: '56px' }} fontSize='inherit' />
            </Box>
            <Typography variant='h4' gutterBottom>
              Oops! Something went wrong.
            </Typography>
            <Box sx={{ mx: 'auto' }}>
              <Button variant='outlined' color='primary' onClick={handleRetryButtonClick}>
                Retry
              </Button>
            </Box>
          </Stack>
        ) : (
          <CircularProgress color='info' size={40} thickness={6} />
        )}
      </Backdrop>
      {shouldRenderApp && children}
    </>
  )
}

TenantAuthenticate.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TenantAuthenticate
