import { createSelector } from 'reselect'

import { ContentMetadataPosition } from '@tabeeb/enums'
import { getContentMetadataPosition } from '@tabeeb/modules/appConfigState/selectors'
import { DIALOG_DEFAULT_SIZE, MIN_DIALOG_SIZE } from '@tabeeb/modules/discussion/constants'
import { getCurrentUserGuid } from '@tabeeb/modules/account/selectors'
import sheetStatus from '../services/sheetStatus'

export const getIsDialogOpen = (state) => state.contentMetadata.isMetadataOpen

export const getAllTabs = (state) => state.contentMetadata.metadataTabs

export const getMetadata = (state) => state.contentMetadata.metadata

export const getIsMetadataLoading = (state) => state.contentMetadata.isMetadataLoading
export const getAreMetadataTabsLoading = (state) => state.contentMetadata.areTabsLoading

export const getPageNumber = (state) => state.contentMetadata.pageNumber

export const getIsExportModeEnabled = (state) => state.contentMetadata.isExportModeEnabled
export const getMetadataTabsForExport = (state) => state.contentMetadata.metadataTabsForExport

export const getIsNoMetadataTabSelectedForExport = createSelector([getMetadataTabsForExport], (tabsForExport) => {
  return tabsForExport.length === 0
})

export const areAllMetadataTabsSelectedForExport = createSelector(
  [getAllTabs, getMetadataTabsForExport],
  (tabs, tabsForExport) => {
    return tabsForExport.length === tabs.length
  }
)

export const getRedoActions = (state) => state.contentMetadata.redoActions
export const getUndoActions = (state) => state.contentMetadata.undoActions

export const getIsRedoButtonDisabled = createSelector([getRedoActions], (redoActions) => {
  return redoActions.length === 0
})

export const getIsUndoButtonDisabled = createSelector([getUndoActions], (undoActions) => {
  return undoActions.length === 0
})

export const getInitialDialogState = createSelector([getContentMetadataPosition], (metadataPosition) => {
  const minSize = { height: MIN_DIALOG_SIZE.height, width: 416 }
  const defaultSize = {
    height: DIALOG_DEFAULT_SIZE.height,
    width: metadataPosition == ContentMetadataPosition.Center ? DIALOG_DEFAULT_SIZE.width : 412,
  }
  const position = {
    x: metadataPosition == ContentMetadataPosition.Center ? undefined : 0,
    y: undefined,
  }

  return { minSize, position, defaultSize }
})

export const getIsSheetLocked = createSelector([getAllTabs, (_, { sheetId }) => sheetId], (sheets, sheetId) => {
  const sheet = sheets.find((s) => s.Id === sheetId)
  return sheet.Status === sheetStatus.Locked
})

export const getCanUnlockSheet = createSelector(
  [getAllTabs, getCurrentUserGuid, (_, { sheetId }) => sheetId],
  (sheets, currentUserGuid, sheetId) => {
    const sheet = sheets.find((s) => s.Id === sheetId)
    return sheet.CreatedByUserId === currentUserGuid
  }
)

export const getIsSheetNameUniqueSelector = (state) => state.contentMetadata.getIsSheetNameUnique
