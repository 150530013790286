import { put, takeLatest, all, select } from 'redux-saga/effects'

import { notificationActions } from '@tabeeb/modules/notification'
import { contentStateSelectors } from '@tabeeb/modules/shared/content'
import { contentMetadataSelectors } from '@tabeeb/modules/contentMetadata'
import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'
import { getCurrentUserEmail } from '@tabeeb/modules/account/selectors'
import { getContentDescription } from '@tabeeb/modules/shared/content/selectors'
import { signalrEvents, signalrConstants } from '@tabeeb/modules/signalr'
import * as actions from '../actions'
import { getRedoActions, getUndoActions } from '../selectors'

function* contentMetadataDialogOpen() {
  const contentId = yield select(contentStateSelectors.getContentId)
  yield put(actions.getContentMetadataTabs.request({ contentId }))
}

function* getTabsFailed() {
  yield put(notificationActions.onAddErrorNotification({ message: 'Could not get the metadata.' }))
}

function* getContentMetadataFailed() {
  yield put(notificationActions.onAddErrorNotification({ message: 'Content metadata not found.' }))

  yield put(actions.resetContentMetadata())
}

function* selectAllContentMetadataTabsForExport() {
  const tabs = yield select(contentMetadataSelectors.getAllTabs)

  const tabsForExport = yield select(contentMetadataSelectors.getMetadataTabsForExport)
  const tabIdsForExport = tabsForExport.map((tab) => tab.Id)

  const difference = tabs.filter((tab) => !tabIdsForExport.includes(tab.Id))

  yield put(actions.selectContentMetadataTabForExport(difference))
}

function* exportContentMetadata() {
  const tabsForExport = yield select(contentMetadataSelectors.getMetadataTabsForExport)

  const sheetIds = tabsForExport.map((tab) => tab.Id)
  const email = yield select(getCurrentUserEmail)
  const contentName = yield select(getContentDescription)

  if (sheetIds.length > 0) {
    yield put(actions.exportContentMetadataApi.request({ sheetIds, emails: [email], emailHeader: contentName }))
  }

  const metadataLabel = yield select(appConfigStateSelectors.getContentMetadataButtonLabel)

  yield put(actions.resetContentMetadataExportMode())

  yield put(
    notificationActions.onAddInfoNotification({
      message: `${metadataLabel} sent for export`,
    })
  )
}

function* redo() {
  const redoActions = yield select(getRedoActions)
  const redoAction = redoActions.pop()
  if (!redoAction) {
    return
  }

  const { action } = redoAction

  yield put(action)
  yield put(actions.setRedoActions(redoActions))
}

function* undo() {
  const undoActions = yield select(getUndoActions)
  const undoAction = undoActions.pop()
  if (!undoAction) {
    return
  }

  const { action, redoAction } = undoAction

  yield put(action)
  yield put(actions.setUndoActions(undoActions))

  if (redoAction) {
    yield put(
      actions.addRedoAction({
        action: redoAction,
        undoAction: undoAction.action,
      })
    )
  }
}

function* addUpdateUndoAction({ payload, type }) {
  const metadata = yield select(contentMetadataSelectors.getMetadata)
  const itemBeforeEditing = metadata.data.find((item) => item.rowId === payload.item.rowId)

  yield put(
    actions.addUndoAction({
      action: { type, payload: { item: itemBeforeEditing, tabId: payload.tabId } },
      redoAction: { type, payload: { item: payload.item, tabId: payload.tabId } },
    })
  )
}

function* addAddUndoAction({ payload, response }) {
  const action = actions.deleteRow.request

  yield put(
    actions.addUndoAction({
      action: action({ itemId: response.data[0], tabId: payload.tabId }),
    })
  )
}

function* publishFailed() {
  yield put(notificationActions.onAddInfoNotification({ message: 'Tab is already published.' }))
}
function* publishSucceeded() {
  yield put(notificationActions.onAddSuccessNotification({ message: 'Tab is published successfully.' }))
}

function* onTableRecognitionNotFound() {
  yield put(
    notificationActions.onAddWarningNotification({ message: 'No tables were recognized in the uploaded file.' })
  )
}

function* onTableRecognitionDone() {
  const metadataLabel = yield select(appConfigStateSelectors.getContentMetadataButtonLabel)

  yield put(
    notificationActions.onAddSuccessNotification({
      message: `The recognized tables have been included in the ${metadataLabel}.`,
    })
  )
}

function* onRenameContentMetadataFailed(action) {
  yield put(notificationActions.onAddWarningNotification({ message: action.response.data.Error.Message }))
}

function* contentMetadataSaga() {
  yield all([
    takeLatest(actions.openContentMetadataDialog, contentMetadataDialogOpen),
    takeLatest(actions.getContentMetadataTabs.failed, getTabsFailed),
    takeLatest(actions.getContentMetadataFailed, getContentMetadataFailed),
    takeLatest(actions.selectAllContentMetadataTabsForExport, selectAllContentMetadataTabsForExport),
    takeLatest(actions.exportContentMetadata, exportContentMetadata),
    takeLatest(actions.redo, redo),
    takeLatest(actions.undo, undo),
    takeLatest(actions.updateRow.request, addUpdateUndoAction),
    takeLatest(actions.addRow.success, addAddUndoAction),
    takeLatest(actions.renameContentMetadata.failed, onRenameContentMetadataFailed),
    takeLatest(actions.publishContentMetadata.failed, publishFailed),
    takeLatest(actions.publishContentMetadata.success, publishSucceeded),
    takeLatest(
      signalrEvents[signalrConstants.spreadsheetHubName].onTableRecognitionNotFound,
      onTableRecognitionNotFound
    ),
    takeLatest(signalrEvents[signalrConstants.spreadsheetHubName].onTableRecognitionDone, onTableRecognitionDone),
  ])
}

export default contentMetadataSaga
