import { Box, Grid, Skeleton, Tooltip, Typography, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { PdfViewer, UserInfo, UserInfoSkeleton } from '@tabeeb/uikit'
import usePdfViewer from '@tabeeb/shared/uikit/hooks/usePdfViewer'
import { mimeTypes } from '@tabeeb/shared/uikit/constants'
import { useDialogState } from '@tabeeb/shared/utils/hooks'
import { CertificateScopeType, CertificateScopeTypeDisplayName } from '@tabeeb/enums'
import { Title } from '@tabeeb/modules/contractors/components/ContractVersionDetailsSection/styles'
import { getCurrentUserTenantId } from '@tabeeb/modules/account/selectors'

import CertificateStatusChip from '../CertificateStatusChip'
import CertificatesImageViewer from '../CertificatesImageViewer'
import { userDeletedDisplayText } from '../../constants'

const ReadOnlyCertificateSkeleton = () => (
  <Grid container direction='row' wrap='nowrap' spacing={2}>
    <Grid item sx={{ width: '320px', height: '300px' }}>
      <Skeleton variant='rectangular' width={300} height={200} />
    </Grid>
    <Grid item container direction='row' spacing={1.5} alignContent='flex-start'>
      <Grid item xs={6}>
        <SkeletonPropView label='Serial number' />
      </Grid>
      <Grid item xs={6}>
        <SkeletonPropView label='Authority' />
      </Grid>
      <Grid item xs={6}>
        <SkeletonPropView label='Category' />
      </Grid>
      <Grid item xs={6}>
        <SkeletonPropView label='Scope' />
      </Grid>
      <Grid item xs={12}>
        <Title title='Status' />
        <Skeleton variant='rounded' width={100} height={20} />
      </Grid>
      <Grid item xs={12}>
        <Title title='Owner' />
        <UserInfoSkeleton />
      </Grid>
      <Grid item xs={12}>
        <SkeletonPropView label='Created on' />
      </Grid>
      <Grid item xs={6}>
        <SkeletonPropView label='Valid from' />
      </Grid>
      <Grid item xs={6}>
        <SkeletonPropView label='Valid till' />
      </Grid>
    </Grid>
  </Grid>
)

const SkeletonPropView = ({ label }) => (
  <>
    <Title title={label} />
    <Skeleton variant='body1' />
  </>
)
SkeletonPropView.propTypes = {
  label: PropTypes.string.isRequired,
}

const PropView = ({ label, primaryText }) => (
  <>
    <Title title={label} />
    <Tooltip title={primaryText}>
      <Typography variant='body1' fontWeight={500} mt={0.5} noWrap>
        {primaryText}
      </Typography>
    </Tooltip>
  </>
)
PropView.propTypes = {
  label: PropTypes.string.isRequired,
  primaryText: PropTypes.string.isRequired,
}

const ReadOnlyCertificate = ({ certificate, personal, loadingGetUsers, user }) => {
  const theme = useTheme()
  const [openImageViewer, onOpenImageViewer, onCloseImageViewer] = useDialogState()
  const currentTenantId = useSelector(getCurrentUserTenantId)

  const [numberOfPdfPages, setNumberOfPdfPages, pageNumber, nextPageClick, previousPageClick, setInitialPage] =
    usePdfViewer()

  const pdfViewerProps = useMemo(
    () => ({
      numberOfPdfPages,
      setNumberOfPdfPages,
      nextPageClick,
      previousPageClick,
      pageNumber,
      setInitialPage,
    }),
    [nextPageClick, numberOfPdfPages, pageNumber, previousPageClick, setInitialPage, setNumberOfPdfPages]
  )

  const [{ height: pdfHeight, width: pdfWidth }, setCurrentPdfDimensions] = useState({ height: 0, width: 0 })
  const pdfDimensionProps = useMemo(() => {
    const isVerticalPdf = pdfHeight > pdfWidth
    const alignmentProp = isVerticalPdf ? { pageHeight: 400 } : { pageWidth: 320 }
    const pdfPageParentDivSx = {
      border: 1,
      width: '322px',
      maxHeight: '402px',
      ...(isVerticalPdf && { height: '402px' }),
    }

    return {
      pdfPageParentDivSx,
      ...alignmentProp,
    }
  }, [pdfHeight, pdfWidth])

  return (
    <>
      <Grid container direction='row' wrap='nowrap' spacing={2}>
        <Grid item xs={5} sx={{ maxHeight: '500px' }}>
          {certificate.PictureUrl.includes('.pdf') ? (
            <PdfViewer
              pdfFileSource={certificate.PictureUrl}
              numberOfPdfPages={numberOfPdfPages}
              setNumberOfPdfPages={setNumberOfPdfPages}
              nextPageClick={nextPageClick}
              previousPageClick={previousPageClick}
              pageNumber={pageNumber}
              setInitialPage={setInitialPage}
              onRootElementClick={onOpenImageViewer}
              setCurrentPdfDimensions={setCurrentPdfDimensions}
              {...pdfDimensionProps}
            />
          ) : (
            <Box onClick={onOpenImageViewer} sx={{ cursor: 'pointer' }}>
              <input
                alt='Certificate image preview'
                title='Open image'
                style={{ maxWidth: 345, maxHeight: 500, pointerEvents: 'none' }}
                type='image'
                src={certificate.PictureUrl}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={7} container direction='row' spacing={1.5} alignContent='flex-start'>
          <Grid item xs={6}>
            <PropView label='Serial number' primaryText={certificate.SerialNumber} />
          </Grid>
          <Grid item xs={6}>
            <PropView label='Authority' primaryText={certificate.Authority} />
          </Grid>
          <Grid item xs={6}>
            <PropView label='Category' primaryText={certificate.CategoryName} />
          </Grid>
          <Grid item xs={6}>
            <PropView label='Scope' primaryText={CertificateScopeTypeDisplayName[certificate.ScopeId]} />
          </Grid>
          <Grid item xs={12}>
            <PropView label='Type' primaryText={certificate.TypeName} />
          </Grid>
          <Grid item xs={12}>
            <Title title='Status' />
            <Box sx={{ mt: 0.5 }}>
              <CertificateStatusChip statusId={certificate.StatusId} />
            </Box>
          </Grid>
          {!personal && (
            <Grid item xs={12}>
              {certificate.ScopeId === CertificateScopeType.TenantCertificate ? (
                <PropView label='Owner' primaryText={`Tenant: ${certificate.TenantName}`} />
              ) : (
                <>
                  <Title title='Owner' />
                  {loadingGetUsers ? (
                    <UserInfoSkeleton />
                  ) : (
                    <UserInfo
                      item={
                        currentTenantId === user?.TenantId ? user : { Email: user?.Email ?? userDeletedDisplayText }
                      }
                    />
                  )}
                </>
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            <PropView
              label='Created on'
              primaryText={moment(certificate.CreatedOn, 'YYYY-MM-DDTHH:mm:ss.SSSSSS').format('MM-DD-YYYY hh:mm A')}
            />
          </Grid>
          <Grid item xs={6}>
            <PropView label='Valid from' primaryText={new Date(certificate.ValidFrom).toLocaleDateString('en-US')} />
          </Grid>
          <Grid item xs={6}>
            <PropView
              label='Valid till'
              primaryText={new Date(certificate.ExpirationDate).toLocaleDateString('en-US')}
            />
          </Grid>
          {certificate.ProfileIdentityCertificates?.length > 0 && (
            <Grid
              item
              xs={12}
              container
              direction='column'
              spacing={0.5}
              sx={{ border: 1, borderColor: 'grey.400', borderRadius: 1, mt: 2, p: 1 }}
            >
              <Grid item>
                <Typography variant='body2' color='#8A898E' fontWeight={500} noWrap>
                  Reviews on tenants
                </Typography>
              </Grid>
              {certificate.ProfileIdentityCertificates.map((relatedCertificate) => (
                <Grid item key={relatedCertificate.Id} container direction='row' spacing={1}>
                  <Grid item xs={6}>
                    <Box>
                      <Typography variant='body2' color='#8A898E' fontWeight={500} noWrap>
                        Tenant
                      </Typography>
                      <Typography sx={{ fontSize: theme.typography.body1.fontSize }}>
                        {relatedCertificate.TenantName}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Typography variant='body2' color='#8A898E' fontWeight={500} noWrap>
                        Review status
                      </Typography>
                      <CertificateStatusChip
                        statusId={relatedCertificate.StatusId}
                        fontSize={theme.typography.body1.fontSize}
                      />
                    </Box>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
      <CertificatesImageViewer
        open={openImageViewer}
        onClose={onCloseImageViewer}
        slides={[{ src: `${certificate.PictureUrl}` }].map((el) => ({
          ...el,
          download: { url: el.src },
          mimeType: `${el.src.includes('.pdf') ? mimeTypes.pdf : ''}`,
        }))}
        pdfViewerProps={pdfViewerProps}
      />
    </>
  )
}

ReadOnlyCertificate.propTypes = {
  certificate: PropTypes.shape({
    Id: PropTypes.number,
    SerialNumber: PropTypes.string,
    Authority: PropTypes.string,
    CategoryId: PropTypes.number,
    CategoryName: PropTypes.string,
    ScopeId: PropTypes.number,
    TypeId: PropTypes.number,
    TypeName: PropTypes.string,
    ValidFrom: PropTypes.string,
    ExpirationDate: PropTypes.string,
    PictureUrl: PropTypes.string,
    CreatedOn: PropTypes.string,
    TenantName: PropTypes.string,
    StatusId: PropTypes.number,
    IsDeleted: PropTypes.bool,
    ProfileIdentityCertificates: PropTypes.arrayOf(
      PropTypes.shape({
        IdentityUserCertificateId: PropTypes.number,
        OwnerProfileId: PropTypes.number,
        TenantId: PropTypes.number,
        TenantName: PropTypes.string,
        ReviewerGuid: PropTypes.string,
        Id: PropTypes.number,
        StatusId: PropTypes.number,
        IsDeleted: PropTypes.bool,
        CreatedOn: PropTypes.string,
      })
    ),
  }),
  personal: PropTypes.bool.isRequired,
  loadingGetUsers: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    Email: PropTypes.string,
    Name: PropTypes.string,
    AvatarUrl: PropTypes.string,
    TenantId: PropTypes.number,
  }),
}

export default ReadOnlyCertificate

export { ReadOnlyCertificateSkeleton }
