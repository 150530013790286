import { useMemo } from 'react'

import { isImageAsset } from '@tabeeb/services/pageService'
import { useApiRequest } from '@tabeeb/modules/shared/utils/hooks'

import { getGalleryItemRequest } from '../actions'

const usePageImageUrl = (pageId) => {
  const payload = useMemo(
    () => ({
      pageId,
    }),
    [pageId]
  )

  const { loading, response: page } = useApiRequest({
    enabled: Boolean(pageId),
    request: getGalleryItemRequest,
    payload,
  })

  return useMemo(() => {
    if (!page || !pageId || loading) {
      return null
    }

    return page.Assets.find((asset) => isImageAsset(asset.Type))?.Url || page?.ThumbnailUrl || null
  }, [loading, page, pageId])
}

export default usePageImageUrl
