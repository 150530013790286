import contentRequests from './contentRequests'
import galleryRequests from './galleryRequests'
import recordingRequests from './recordingRequests'
import formsRequests from './formsRequests'
import uploadRequests from './uploadRequests'
import rotatePageRequests from './rotatePageRequests'
import * as calendarRequests from './calendarRequests'
import customVideoRequests from './customVideoRequests'
import usersRequests from './usersRequests'
import sessionsPageRequests from './sessionsPageRequests'
import formsPageRequests from './formsPageRequests'
import formStatisticsPageRequests from './formStatisticsPageRequests'
import activitiesRequests from './activitiesRequests'
import createFormPageRequests from './createFormPageRequests'
import geosearchRequests from './geosearchRequests'
import chatMessagesRequests from './chatMessagesRequests'
import assetsRequests from './assetsRequest'
import annotationRequests from './annotationRequests'
import articlesRequests from './articlesRequests'
import aiRequests from './aiRequests'
import spatialModelRequests from './spatialModelRequests'
import configRequests from './configRequests'
import tenantSessionsPageRequests from './tenantSessionsPageRequests'
import pointCloudRequests from './pointCloudRequests'
import policiesRequests from './policiesRequests'
import * as accountRequests from './accountRequests'
import adminRequests from './adminRequests'
import notificationsPageRequests from './notificationsPageRequests'
import allFormsRequests from './allFormsRequests'
import tenantsRequests from './tenantsRequests'
import trackingRequests from './trackingRequests'
import contentReviewsRequests from './contentReviewsRequests'
import * as inviteRequests from './inviteRequests'
import devicesRequests from './devicesRequests'
import billingSettingsRequests from './billingSettingsRequests'
import * as permissionsRequests from './permissionsRequests'
import * as remindersRequests from './remindersRequests'
import * as reportsRequests from './reportsRequests'
import nlpModelsRequests from './nlpModelsRequests'
import vqaModelsRequests from './vqaModelsRequests'
import vqaPoliciesRequests from './vqaPoliciesRequests'
import tagsRequests from './tagsRequests'
import towerSideProfileRequests from './towerSideProfileRequests'
import twoDLineDrawingRequests from './twoDLineDrawingRequests'
import healthDataRequests from './healthDataRequests'
import bentleyRequests from './bentleyRequests'
import powerBIRequests from './powerBiRequests'
import contentMetadataRequests from './contentMetadataRequests'
import contentSharingRequests from './contentSharingRequests'
import projectsRequests from './projectsRequests'
import iterationsRequests from './iterationsRequests'
import aiExpressionsRequests from './aiExpressionsRequests'
import aiModelsRequests from './aiModelsRequests'
import predictionSetsRequests from './predictionSetsRequests'
import modelTrainingRequests from './modelTrainingRequests'
import advancedFormSettingsRequests from './advancedFormSettingsRequests'
import metricsRequests from './metricsRequests'
import aiReportRequests from './aiReportRequests'
import maintenanceRequests from './maintenanceRequests'
import pointCloudGpsMetadataRequests from './pointCloudGpsMetadataRequests'
import sncFiltersRequests from './sncFiltersRequests'
import threeDModelRequests from './threeDModelRequests'
import certificatesRequests from './certificatesRequests'
import contractorsRequests from './contractorsRequests'
import workflowsRequests from './workflowsRequests'
import powerBIReportProvidersRequests from './powerBIReportProvidersRequests'
import uniqueAiObjectConnectionRequests from './uniqueAiObjectConnectionRequests'
import utilityInfrastructureRequests from './utilityInfrastructureRequests'

const API = {
  ...contentRequests,
  ...galleryRequests,
  ...recordingRequests,
  ...formsRequests,
  ...uploadRequests,
  ...rotatePageRequests,
  ...calendarRequests,
  ...customVideoRequests,
  ...usersRequests,
  ...sessionsPageRequests,
  ...formsPageRequests,
  ...activitiesRequests,
  ...formStatisticsPageRequests,
  ...createFormPageRequests,
  ...geosearchRequests,
  ...chatMessagesRequests,
  ...assetsRequests,
  ...annotationRequests,
  ...articlesRequests,
  ...aiRequests,
  ...spatialModelRequests,
  ...configRequests,
  ...tenantSessionsPageRequests,
  ...trackingRequests,
  ...pointCloudRequests,
  ...policiesRequests,
  ...accountRequests,
  ...allFormsRequests,
  ...tenantsRequests,
  ...adminRequests,
  ...notificationsPageRequests,
  ...contentReviewsRequests,
  ...devicesRequests,
  ...billingSettingsRequests,
  ...permissionsRequests,
  ...remindersRequests,
  ...reportsRequests,
  ...nlpModelsRequests,
  ...vqaModelsRequests,
  ...vqaPoliciesRequests,
  ...tagsRequests,
  ...towerSideProfileRequests,
  ...twoDLineDrawingRequests,
  ...healthDataRequests,
  ...inviteRequests,
  ...bentleyRequests,
  ...powerBIRequests,
  ...contentMetadataRequests,
  ...contentSharingRequests,
  ...projectsRequests,
  ...iterationsRequests,
  ...aiExpressionsRequests,
  ...aiModelsRequests,
  ...predictionSetsRequests,
  ...modelTrainingRequests,
  ...advancedFormSettingsRequests,
  ...metricsRequests,
  ...aiReportRequests,
  ...maintenanceRequests,
  ...sncFiltersRequests,
  ...pointCloudGpsMetadataRequests,
  ...threeDModelRequests,
  ...certificatesRequests,
  ...contractorsRequests,
  ...powerBIReportProvidersRequests,
  ...workflowsRequests,
  ...uniqueAiObjectConnectionRequests,
  ...utilityInfrastructureRequests,
}

export default API
