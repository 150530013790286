import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SatelliteOutlined } from '@material-ui/icons'

import { toggleSourcePagesNavigation } from '@tabeeb/modules/pointCloud/actions'
import { getIsSourcePagesNavigationAvailable } from '@tabeeb/modules/pointCloud/selectors'

import PageInfoControl from '../PageInfoControl'

const PointCloudSourcePagesNavigationButton = () => {
  const dispatch = useDispatch()

  const available = useSelector(getIsSourcePagesNavigationAvailable)

  const onToggle = useCallback(() => {
    dispatch(toggleSourcePagesNavigation())
  }, [dispatch])

  if (!available) {
    return null
  }

  return <PageInfoControl icon={SatelliteOutlined} title='Toggle source images navigation' onClick={onToggle} />
}

export default memo(PointCloudSourcePagesNavigationButton)
