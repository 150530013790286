import {
  Numbers,
  ExpandCircleDownOutlined,
  EventOutlined,
  Link,
  ToggleOff,
  DataObjectOutlined,
  PlaceRounded,
  CheckBoxOutlined,
  FormatAlignLeft,
} from '@mui/icons-material'
import { AIObjectPropertyType } from '@tabeeb/enums'

export const AIObjectPropertyTypeIcon = {
  [AIObjectPropertyType.Json]: <DataObjectOutlined fontSize='small' />,
  [AIObjectPropertyType.String]: <FormatAlignLeft fontSize='small' />,
  [AIObjectPropertyType.IntNumber]: <Numbers fontSize='small' />,
  [AIObjectPropertyType.DoubleNumber]: <Numbers fontSize='small' />,
  [AIObjectPropertyType.DateTime]: <EventOutlined fontSize='small' />,
  [AIObjectPropertyType.Dropdown]: <ExpandCircleDownOutlined fontSize='small' />,
  [AIObjectPropertyType.Checkbox]: <CheckBoxOutlined fontSize='small' />,
  [AIObjectPropertyType.Url]: <Link fontSize='small' />,
  [AIObjectPropertyType.Boolean]: <ToggleOff fontSize='small' />,
  [AIObjectPropertyType.Latitude]: <PlaceRounded fontSize='small' />,
  [AIObjectPropertyType.Longitude]: <PlaceRounded fontSize='small' />,
}

export const UniqueAIObjectRoleInConnection = {
  Parent: 0,
  Child: 1,
  Projection: 2,
}

export const UniqueAIObjectRoleInConnectionDisplayName = {
  [UniqueAIObjectRoleInConnection.Parent]: 'Parent',
  [UniqueAIObjectRoleInConnection.Child]: 'Child',
  [UniqueAIObjectRoleInConnection.Projection]: 'Projection',
}

export const UniqueAIObjectRoleInConnectionDescription = {
  [UniqueAIObjectRoleInConnection.Parent]: 'A parent of another object.',
  [UniqueAIObjectRoleInConnection.Child]: 'A child of another object.',
  [UniqueAIObjectRoleInConnection.Projection]: 'Represents the same object as another object in another session.',
}
