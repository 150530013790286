import { put, takeLatest, all, select } from 'redux-saga/effects'

import { rawContentActions } from '@tabeeb/shared/content'
import * as rawActions from '../actions'
import * as notificationActions from '../../notification/actions'
import { addFolderToSplatModel } from '@tabeeb/modules/gallery/actions'
import { getPagesByFolderWithNesting } from '@tabeeb/modules/gallery/selectors'
import { isImagePage } from '@tabeeb/services/pageService'

function* onAddFolderToSplatModel({ payload: { folderId } }) {
  let pages = yield select((state) => getPagesByFolderWithNesting(state, { id: folderId }))

  pages = pages.filter((item) => isImagePage(item.contentType))

  if (pages.length < 1) {
    yield put(
      notificationActions.onAddWarningNotification({
        message: 'Folder is empty!',
      })
    )

    return
  }

  yield put(rawActions.addAllPagesToSplatModel(pages))
}

function* createSplatModelFailed(action) {
  yield put(
    notificationActions.onAddErrorNotification({
      message: 'Failed to create a SPLAT model',
    })
  )
}

function* createSplatModelSuccess() {
  yield put(rawActions.closeSplatModelTimeline())

  yield put(
    notificationActions.onAddInfoNotification({
      message: 'Model sent for creation',
    })
  )
}

function* resetState() {
  yield put(rawActions.resetState())
}

function* splatModelSaga() {
  yield all([
    takeLatest(rawActions.createSplatModel.failed, createSplatModelFailed),
    takeLatest(rawActions.createSplatModel.success, createSplatModelSuccess),
    takeLatest(rawContentActions.resetContentState, resetState),
    takeLatest(addFolderToSplatModel, onAddFolderToSplatModel),
  ])
}

export default splatModelSaga
