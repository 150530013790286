import { createAction } from 'redux-actions'
import { createApiActions } from '@tabeeb/shared/utils/actions'

export const openContentMetadataDialog = createAction('OPEN_CONTENT_METADATA_DIALOG')
export const closeContentMetadataDialog = createAction('CLOSE_CONTENT_METADATA_DIALOG')

export const getContentMetadataTabs = createApiActions('GET_CONTENT_METADATA_TABS')

export const getContentMetadataRowAmountRequest = createAction('GET_CONTENT_METADATA_ROW_AMOUNT_REQUEST')
export const getContentMetadataRowAmountSuccess = createAction('GET_CONTENT_METADATA_ROW_AMOUNT_SUCCESS')

export const getContentMetadataRequest = createAction('GET_CONTENT_METADATA_REQUEST')
export const getContentMetadataSuccess = createAction('GET_CONTENT_METADATA_SUCCESS')
export const getContentMetadataFailed = createAction('GET_CONTENT_METADATA_FAILED')

export const resetContentMetadataTabNames = createAction('RESET_CONTENT_METADATA_TAB_NAMES')
export const resetContentMetadata = createAction('RESET_CONTENT_METADATA')
export const clearContentMetadataValues = createAction('CLEAR_CONTENT_METADATA_VALUES')

export const setContentMetadataExportMode = createAction('SET_CONTENT_METADATA_EXPORT_MODE')
export const resetContentMetadataExportMode = createAction('RESET_CONTENT_METADATA_EXPORT_MODE')

export const exportContentMetadata = createAction('EXPORT_CONTENT_METADATA')
export const exportContentMetadataApi = createApiActions('EXPORT_CONTENT_METADATA')

export const selectContentMetadataTabForExport = createAction('SELECT_CONTENT_METADATA_TAB_FOR_EXPORT')
export const deselectContentMetadataTabForExport = createAction('DESELECT_CONTENT_METADATA_TAB_FOR_EXPORT')

export const selectAllContentMetadataTabsForExport = createAction('SELECT_ALL_CONTENT_METADATA_TABS_FOR_EXPORT')
export const deselectAllContentMetadataTabsForExport = createAction('DESELECT_ALL_CONTENT_METADATA_TABS_FOR_EXPORT')

export const getFilterValues = createApiActions('GET_FILTER_VALUES')
export const setFilterValueIds = createAction('SET_FILTER_VALUE_IDS')
export const setFilterColumns = createAction('SET_FILTER_COLUMNS')

export const requestFilteredValues = createAction('REQUEST_FILTERED_VALUES')

export const addRow = createApiActions('ADD_ROW')
export const updateRow = createApiActions('UPDATE_ROW')
export const deleteRow = createApiActions('DELETE_ROW')

export const redo = createAction('CONTENT_METADATA_TOOLBAR_REDO')
export const undo = createAction('CONTENT_METADATA_TOOLBAR_UNDO')

export const setRedoActions = createAction('CONTENT_METADATA_TOOLBAR_SET_REDO_ACTIONS')
export const setUndoActions = createAction('CONTENT_METADATA_TOOLBAR_SET_UNDO_ACTIONS')

export const addRedoAction = createAction('CONTENT_METADATA_TOOLBAR_ADD_REDO_ACTIONS')
export const addUndoAction = createAction('CONTENT_METADATA_TOOLBAR_ADD_UNDO_ACTIONS')

export const publishContentMetadata = createApiActions('PUBLISH_CONTENT_METADATA')

export const renameContentMetadata = createApiActions('RENAME_CONTENT_METADATA')

export const getIsSheetNameUnique = createApiActions('GET_IS_SHEET_NAME_UNIQUE')

export const updateSheet = createApiActions('UPDATE_SHEET')
