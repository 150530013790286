import { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import * as contentStateSelectors from '@tabeeb/shared/content/selectors'
import * as aiActions from '@tabeeb/modules/artificialIntelligence/actions'
import * as aiSelectors from '@tabeeb/modules/artificialIntelligence/selectors'

import { Autocomplete, Box, FormControl, TextField, Typography } from '@mui/material'
import { UniqueAIObjectCreationMode } from '@tabeeb/enums'
import { StyledTextField } from './styles'

import AIAnnotationEditorDimensions from '../AIAnnotationEditorDimensions'
import AIAnnotationEditorModeButton from '../AIAnnotationEditorModeButton'
import { getUniqueAIObjectCreationMode } from '../../selectors'

const maxUniqueAiObjectNameLength = 500

const AIAnnotationEditor = ({ onChange }) => {
  const dispatch = useDispatch()

  const [newUniqueAiObjectName, setNewUniqueAiObjectName] = useState('')
  const [selectedUniqueAiObject, setSelectedUniqueAiObject] = useState(null)

  const selectedAiObject = useSelector(aiSelectors.getSelectedAiObject)
  const contentId = useSelector(contentStateSelectors.getContentId)
  const aiObjects = useSelector(aiSelectors.getAIObjects)
  const uniqueAiObjects = useSelector((state) =>
    aiSelectors.getUniqueAIObjectsByAIObjectId(state, { Id: selectedAiObject.Id })
  )

  const uniqueAIObjectCreationMode = useSelector(getUniqueAIObjectCreationMode)

  const handleChangeAIObject = useCallback(
    (e, value, reason) => {
      if (!value) {
        return
      }

      const newAIObjectId = value.Id
      const selectedAIObject = aiObjects.find((object) => object.Id === newAIObjectId)

      setSelectedUniqueAiObject(null)
      dispatch(aiActions.setSelectedAiObject(selectedAIObject))
      dispatch(aiActions.getUniqueAiObjects.request({ aiObjectId: newAIObjectId, contentId }))
    },
    [aiObjects, contentId, dispatch]
  )

  const handleChangeUniqueAIObject = useCallback((e, value) => {
    setSelectedUniqueAiObject(value)
  }, [])

  const handleChangeText = useCallback((e) => {
    setNewUniqueAiObjectName(e.target.value)
  }, [])

  useEffect(() => {
    const isUniqueAiObjectAlreadyExists = uniqueAiObjects.some((item) => item.Name === newUniqueAiObjectName)
    const isMaxLengthExceeded = newUniqueAiObjectName.length > maxUniqueAiObjectNameLength
    const isPartiallyFilled =
      uniqueAIObjectCreationMode === UniqueAIObjectCreationMode.CreateNewFromExisting &&
      Boolean(newUniqueAiObjectName) !== Boolean(selectedUniqueAiObject)
    if (isUniqueAiObjectAlreadyExists || isMaxLengthExceeded || isPartiallyFilled) {
      onChange(undefined)
      return
    }

    onChange({
      newUniqueAiObjectName,
      selectedAiObject,
      selectedUniqueAiObject: selectedUniqueAiObject || null,
      uniqueAIObjectCreationMode,
    })
  }, [selectedAiObject, selectedUniqueAiObject, newUniqueAiObjectName, uniqueAIObjectCreationMode])

  const isUniqueAiObjectAlreadyExists = uniqueAiObjects.some((item) => item.Name === newUniqueAiObjectName)
  const isMaxLengthExceeded = newUniqueAiObjectName.length > maxUniqueAiObjectNameLength
  const isPartiallyFilled =
    uniqueAIObjectCreationMode === UniqueAIObjectCreationMode.CreateNewFromExisting &&
    Boolean(newUniqueAiObjectName) !== Boolean(selectedUniqueAiObject)

  const customFilterOptions = (options, state) => {
    if (!state.inputValue) {
      return options
    }

    const pattern = state.inputValue.replace(/ /g, '.*')
    const regex = new RegExp(pattern, 'i')

    return options.filter((option) => regex.test(option.Name))
  }

  return (
    <Box display='flex' gap={2} flexDirection='column'>
      <AIAnnotationEditorDimensions />
      <FormControl size='small' variant='outlined' fullWidth sx={{ mt: 1 }}>
        <Autocomplete
          fullWidth
          value={selectedAiObject}
          onChange={handleChangeAIObject}
          options={aiObjects}
          filterOptions={customFilterOptions}
          getOptionLabel={(option) => option.Name}
          renderTags={() => []}
          isOptionEqualToValue={(option, value) => value.Id === option.Id}
          renderOption={(props, option) => (
            <Typography {...props} noWrap title={option.Name} key={option.Id}>
              {option.Name}
            </Typography>
          )}
          renderInput={(params) => (
            <TextField {...params} autoFocus variant='outlined' label='CV class' placeholder='Search a CV class...' />
          )}
        />
      </FormControl>
      <AIAnnotationEditorModeButton />
      {(uniqueAIObjectCreationMode === UniqueAIObjectCreationMode.SelectExisting ||
        uniqueAIObjectCreationMode === UniqueAIObjectCreationMode.CreateNewFromExisting) && (
        <FormControl size='small' variant='outlined' fullWidth>
          <Autocomplete
            fullWidth
            value={selectedUniqueAiObject}
            onChange={handleChangeUniqueAIObject}
            options={uniqueAiObjects}
            getOptionLabel={(option) => option.Name}
            filterOptions={customFilterOptions}
            renderTags={() => []}
            isOptionEqualToValue={(option, value) => value.Id === option.Id}
            renderOption={(props, option) => (
              <Typography {...props} noWrap title={option.Name} key={option.Id}>
                {option.Name}
              </Typography>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                label={`Select ${selectedAiObject.Name}`}
                placeholder='Search an object...'
                helperText={isPartiallyFilled && !selectedUniqueAiObject && `Required`}
                error={isPartiallyFilled && !selectedUniqueAiObject}
              />
            )}
          />
        </FormControl>
      )}
      {(uniqueAIObjectCreationMode === UniqueAIObjectCreationMode.CreateNew ||
        uniqueAIObjectCreationMode === UniqueAIObjectCreationMode.CreateNewFromExisting) && (
        <StyledTextField
          fullWidth
          value={newUniqueAiObjectName}
          size='small'
          variant='outlined'
          label='Object name'
          onChange={handleChangeText}
          helperText={
            (isUniqueAiObjectAlreadyExists && 'An object with the same name already exists') ||
            (isMaxLengthExceeded && `Max length is ${maxUniqueAiObjectNameLength}`) ||
            (isPartiallyFilled && !newUniqueAiObjectName && `Required`)
          }
          error={isUniqueAiObjectAlreadyExists || isMaxLengthExceeded || (isPartiallyFilled && !newUniqueAiObjectName)}
          placeholder='Enter object name here...'
        />
      )}
    </Box>
  )
}

AIAnnotationEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default AIAnnotationEditor
