import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'

const CertificatesGridToolbar = ({ showSearch }) => {
  return (
    <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
      <Box>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </Box>
      {showSearch && <GridToolbarQuickFilter debounceMs={700} />}
    </GridToolbarContainer>
  )
}

CertificatesGridToolbar.defaultProps = {
  showSearch: true,
}

CertificatesGridToolbar.propTypes = {
  showSearch: PropTypes.bool,
}
export default CertificatesGridToolbar
