import { AnswerStatusFilterOptions } from '@tabeeb/enums'

export const MIN_DIALOG_SIZE = {
  width: 386,
  height: 512,
}

export const DIALOG_DEFAULT_SIZE = {
  width: 768,
  height: 768,
}

export const CHAT_HEIGHT = 300

export const ANSWERS_TAKE = 20

export const FilterTypes = {
  ByUser: 'userId',
  ByForm: 'formId',
  ByStatuses: 'statuses',
}

export const FilterDefaultValues = {
  [FilterTypes.ByUser]: -1,
  [FilterTypes.ByForm]: -1,
  [FilterTypes.ByStatuses]: [
    AnswerStatusFilterOptions.Approved,
    AnswerStatusFilterOptions.Rejected,
    AnswerStatusFilterOptions.Submitted,
  ],
}
