import PropTypes from 'prop-types'

import { getImageRatio } from '@tabeeb/services/pageService'
import GalleryItemContainer from '../../containers/GalleryItemContainer'

import FoldersListContainer from '../../containers/FoldersListContainer'
import LazyLoad from '../../../shared/lazyLoadComponent'

import GalleryItemsMenuContextProvider from '../GalleryItemsMenuContextProvider'

import './styles.less'

const getGalleryItemPlaceholder = (item) => {
  const pageRatio = getImageRatio(item)
  const pageHeight = 160 * pageRatio + 68
  return <div id={item.id} className='gallery-item-placeholder' style={{ height: pageHeight }} />
}

const GalleryList = ({ galleryList, listRef, setSelectedItemRef, isGalleryExpanded, isDragAvailable }) => {
  const galleryItemProps = {
    listRef,
    setSelectedItemRef,
  }

  return (
    <GalleryItemsMenuContextProvider>
      <ul className='gallery-list'>
        <FoldersListContainer />
        {galleryList.map((item, index) => {
          return (
            <LazyLoad
              key={item.id}
              offset={2000}
              scroll
              once
              debounce
              scrollContainer='.gallery-scrollable'
              placeholder={getGalleryItemPlaceholder(item)}
            >
              <GalleryItemContainer key={item.id} id={item.id} index={index} {...galleryItemProps} />
            </LazyLoad>
          )
        })}
        <div className='gallery-help-text' style={{ fontSize: isGalleryExpanded ? 24 : 18 }}>
          {isDragAvailable && <span>Drag and drop</span>}
        </div>
      </ul>
    </GalleryItemsMenuContextProvider>
  )
}

GalleryList.propTypes = {
  galleryList: PropTypes.array.isRequired,
  listRef: PropTypes.object,
  setSelectedItemRef: PropTypes.func,
  isGalleryExpanded: PropTypes.bool.isRequired,
  isDragAvailable: PropTypes.bool.isRequired,
}

export default GalleryList
