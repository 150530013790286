import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'

import { AutoFixHigh } from '@mui/icons-material'
import { Button, Tooltip } from '@mui/material'

import { getAnnotationParent } from '@tabeeb/modules/annotations/selectors'
import { Units } from '@tabeeb/modules/pointCloud/constants'
import { getScale, getUnit } from '@tabeeb/modules/pointCloud/selectors'
import { FEET_IN_METER } from '@tabeeb/modules/pointCloud/utils/measurements'

import { AutofillSource } from '@tabeeb/modules/artificialIntelligence/enums'

import { getSelectedUniqueAIObjectAnnotation } from '../../../selectors'

const PRECISION = 2

const toPrecision = (value, precision) => {
  return parseFloat(value.toFixed(precision), 10)
}

const AutofillButton = () => {
  const { values, setFieldValue } = useFormikContext()

  const aiObjectAnnotation = useSelector(getSelectedUniqueAIObjectAnnotation)
  const aiObjectAnnotationShape = useSelector((state) =>
    getAnnotationParent(state, { Id: aiObjectAnnotation?.ParentAnnotationId })
  )

  const worldScale = useSelector(getScale)
  const unit = useSelector(getUnit)

  const autofillProperties = values.properties.filter(
    (property) => property.isEditable && property.autofillSource !== AutofillSource.None
  )
  if (!aiObjectAnnotationShape || autofillProperties.length === 0) {
    return null
  }

  return (
    <Tooltip title='Autofill'>
      <Button
        sx={{ position: 'absolute', top: 16, right: 24 }}
        color='primary'
        variant='contained'
        startIcon={<AutoFixHigh />}
        onClick={() => {
          const unitScale = unit === Units.Meters ? 1 : FEET_IN_METER
          const totalScale = worldScale * unitScale

          const shapeProperties = {
            [AutofillSource.Height]: Math.abs(
              toPrecision((aiObjectAnnotationShape?.Scale?.Y ?? 0) * totalScale, PRECISION)
            ),
            [AutofillSource.Width]: Math.abs(
              toPrecision((aiObjectAnnotationShape?.Scale?.X ?? 0) * totalScale, PRECISION)
            ),
            [AutofillSource.Depth]: Math.abs(
              toPrecision((aiObjectAnnotationShape?.Scale?.Z ?? 0) * totalScale, PRECISION)
            ),
            [AutofillSource.Elevation]: toPrecision(
              Math.max(aiObjectAnnotationShape?.Elevation ?? 0, 0) * totalScale,
              PRECISION
            ),
            [AutofillSource.RollAngle]: toPrecision(
              (aiObjectAnnotationShape?.Rotation?.X ?? 0) * (180 / Math.PI),
              PRECISION
            ),
            [AutofillSource.PitchAngle]: toPrecision(
              (360 + (aiObjectAnnotationShape?.Rotation?.Y ?? 0) * (180 / Math.PI)) % 360,
              PRECISION
            ),
            [AutofillSource.YawAngle]: toPrecision(
              (180 + ((aiObjectAnnotationShape?.Rotation?.Z ?? 0 + Math.PI) % (2 * Math.PI)) * (180 / Math.PI)) % 360,
              PRECISION
            ),
          }

          const orderedProperties = values.properties.sort((a, b) => a.ordinal - b.ordinal)

          for (const property of autofillProperties) {
            const index = orderedProperties.findIndex((p) => p.name === property.name)

            setFieldValue(`properties.${index}.value`, shapeProperties[property.autofillSource])
          }
        }}
      >
        Autofill
      </Button>
    </Tooltip>
  )
}

export default AutofillButton
