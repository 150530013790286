import { handleActions } from 'redux-actions'

import {
  addUniqueAiObjectConnection,
  deleteUniqueAiObjectConnection,
  getUniqueAiObjectsConnections,
  resetState,
} from '../actions'

const defaultState = []

export default handleActions(
  {
    [getUniqueAiObjectsConnections.success](state, { payload, response }) {
      const connections = response.data.map((connection) => ({
        Id: connection.Id,
        ChildId: connection.Child.Id,
        ParentId: connection.Parent.Id,
        Type: connection.Type,
      }))

      return connections
    },
    [addUniqueAiObjectConnection.success](state, { payload, response }) {
      const connection = response.data

      return [
        ...state,
        {
          Id: connection.Id,
          ChildId: connection.Child.Id,
          ParentId: connection.Parent.Id,
          Type: connection.Type,
        },
      ]
    },
    [deleteUniqueAiObjectConnection.success](state, { payload: { uniqueAIObjectConnectionId } }) {
      return state.filter((connection) => connection.Id !== uniqueAIObjectConnectionId)
    },
    [resetState](state, payload) {
      return defaultState
    },
  },
  defaultState
)
