import { ControlType, FormItemType } from '../../../Enums'
import { generateId } from './generateTempId'

export function createAction(control, controlsList, mode, formId, tooltip, controlLabel, order) {
  let newAction = {}

  newAction.TempId = mode === 'import' ? order : generateId(controlsList)
  newAction.FormItemType = FormItemType.Action
  newAction.Order = mode === 'import' ? order : controlsList.length
  newAction.Label = mode === 'import' ? controlLabel : control
  newAction.ControlType = ControlType.Action
  newAction.ApiUrl = ''
  newAction.ApiKey = ''

  if (mode === 'edit') {
    newAction = {
      ...newAction,
      FormId: formId,
    }
  }

  return newAction
}
