import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TabPanel } from '@tabeeb/enums'
import Timeline from '@tabeeb/modules/timeline/components/Timeline'
import { getNavbarCurrentBottomPanel } from '@tabeeb/modules/customVideo/selectors'
import {
  closeSplatModelTimeline,
  createSplatModel,
  deleteImageFromSplatModel,
  updateImagesSplatModel,
} from '../../actions'
import { getIsTimelineOpen, getPagesList } from '../../selectors'
import { PAGES_REQUIRED_FOR_SPLAT_MODEL } from '../../services/constants'

const SplatModelTimeline = () => {
  const dispatch = useDispatch()
  const pages = useSelector(getPagesList)
  const isOpen = useSelector(getIsTimelineOpen)
  const currentPanel = useSelector(getNavbarCurrentBottomPanel)

  const _handleClose = () => {
    dispatch(closeSplatModelTimeline())
  }

  const _handleSubmit = () => {
    if (pages && pages.length >= PAGES_REQUIRED_FOR_SPLAT_MODEL) {
      dispatch(createSplatModel.request({ PageIds: pages.map((p) => p.id) }))
    }
  }

  const _handleDeleteItem = (pageIndex) => {
    dispatch(deleteImageFromSplatModel(pageIndex))
  }

  const _handleUpdateList = (newList) => {
    dispatch(updateImagesSplatModel(newList))
  }

  const actions = [
    {
      onClick: _handleClose,
      title: 'Cancel',
      class: 'close',
    },
    {
      onClick: _handleSubmit,
      title: 'Done',
      class: 'submit',
      tooltip:
        pages.length < PAGES_REQUIRED_FOR_SPLAT_MODEL
          ? `A minimum of ${PAGES_REQUIRED_FOR_SPLAT_MODEL} pages is required to create a SPLAT model.`
          : null,
      disabled: pages.length < PAGES_REQUIRED_FOR_SPLAT_MODEL,
    },
  ]

  const props = {
    actions,
    timeLineList: pages,
    handleDeleteItem: _handleDeleteItem,
    handleUpdateList: _handleUpdateList,
    isOpen,
    isSelected: currentPanel === TabPanel.SplatModel,
  }

  return <Timeline {...props} />
}

export default memo(SplatModelTimeline)
