import { put, takeEvery, select, all } from 'redux-saga/effects'

import selector from '@tabeeb/shared/utils/selector'

import annotationRequests from '@tabeeb/modules/shared/utils/API/annotationRequests'
import { accountSelectors } from '@tabeeb/modules/account'
import { signalrEvents, signalrConstants } from '@tabeeb/modules/signalr'
import { contentStateSelectors } from '@tabeeb/shared/content'
import { galleryActions, gallerySelectors } from '@tabeeb/modules/gallery'
import { activityActions as activitiesActions } from '@tabeeb/modules/activities'
import { convertServiceAnnotationToPluginAnnotation } from '@tabeeb/services/dataConverter'
import * as annotationsActions from '../actions'

function* onAnnotation(action) {
  const [annotation] = action.payload

  yield put(galleryActions.addAnnotation(annotation))

  const convertedAnnotation = yield getActivityFromAnnotation(annotation)
  yield put(activitiesActions.addActivity(convertedAnnotation))
}

function* onAnnotationUpdate(action) {
  const [annotation] = action.payload

  yield put(galleryActions.updateAnnotation(annotation))

  const convertedAnnotation = yield getActivityFromAnnotation(annotation)
  yield put(activitiesActions.updateActivity(convertedAnnotation))
}

function* onAnnotationDeleted(action) {
  const [PageId, Id] = action.payload
  yield put(galleryActions.deleteAnnotations([{ PageId, Id }]))
  yield put(activitiesActions.delActivity(Id))
}

function* onUpdatePageAnnotations(action) {
  const [notification] = action.payload

  const page = yield select((state) => gallerySelectors.getGalleryItemById(state, { id: notification.pageId }))
  if (!page) {
    return
  }

  yield put(annotationsActions.getAnnotationsByPageRequest(notification.pageId))
}

function* onUpdatePagesAnnotations(action) {
  const [notification] = action.payload
  const { pageIds } = notification

  const pages = yield select((state) => gallerySelectors.getGalleryItemsByIds(state, { ids: pageIds }))

  const data = []
  for (const page of pages) {
    if (!page.isLoaded) {
      continue
    }

    data.push(annotationRequests.getAnnotationsByPageRequest(page.id))
  }

  yield put(annotationsActions.getAnnotationsByPageParallelRequests(data))
}

function* onPresenterState(action) {
  const [{ hotspotDialogAnnotationId }] = action.payload

  yield put(annotationsActions.openHotspotDialog({ annotationId: hotspotDialogAnnotationId }))
}

function* getActivityFromAnnotation(annotation) {
  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  const ownerId = yield select(contentStateSelectors.getOwnerId)
  const apiHostName = yield select(selector.getApiUrl)

  const isOwner = ownerId === annotation.UserId
  const convertedAnnotation = convertServiceAnnotationToPluginAnnotation(
    annotation,
    { Id: annotation.PageId },
    isOwner,
    currentUserId,
    apiHostName
  )

  return convertedAnnotation
}

function* signalrSagas() {
  yield all([
    takeEvery(
      [
        signalrEvents[signalrConstants.tabeebHubName].onAnnotation,
        signalrEvents[signalrConstants.tabeebHubName].onAnnotationReceived,
        signalrEvents[signalrConstants.tabeebHubName].onAnnotationRestored,
      ],
      onAnnotation
    ),
    takeEvery(signalrEvents[signalrConstants.tabeebHubName].onAnnotationUpdate, onAnnotationUpdate),
    takeEvery(signalrEvents[signalrConstants.tabeebHubName].onAnnotationDeleted, onAnnotationDeleted),
    takeEvery(signalrEvents[signalrConstants.tabeebHubName].onUpdatePageAnnotations, onUpdatePageAnnotations),
    takeEvery(signalrEvents[signalrConstants.tabeebHubName].onUpdatePagesAnnotations, onUpdatePagesAnnotations),
    takeEvery(signalrEvents[signalrConstants.tabeebHubName].onPresenterState, onPresenterState),
  ])
}

export default signalrSagas
