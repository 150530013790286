import { getScale, getUnit } from '@tabeeb/modules/pointCloud/selectors'
import { FEET_IN_METER } from '@tabeeb/modules/pointCloud/utils/measurements'
import { Units } from '@tabeeb/modules/pointCloud/constants'
import { AnnotationType } from '@tabeeb/enums'
import { useSelector } from 'react-redux'
import { getParentOfHotspotDialogAnnotation } from '../../selectors'

const AIAnnotationEditorDimensions = () => {
  const parent = useSelector(getParentOfHotspotDialogAnnotation)
  const worldScale = useSelector(getScale)
  const unit = useSelector(getUnit)
  const unitScale = unit === Units.Meters ? 1 : FEET_IN_METER
  const totalScale = worldScale * unitScale
  const lengthUnitDisplayName = unit === Units.Meters ? 'm' : 'ft'

  const toPrecision = (value, precision) => {
    return parseFloat(value.toFixed(precision), 10)
  }

  return (
    parent &&
    (parent.Type === AnnotationType.Cylinder || parent.Type === AnnotationType.Box) && (
      <div style={{ display: 'flex', justifyContent: 'space-evenly', padding: '8px' }}>
        <span>
          <strong>Width, {lengthUnitDisplayName}:</strong> {toPrecision((parent?.Scale?.X ?? 0) * totalScale, 2)}
        </span>
        <span>
          <strong>Height, {lengthUnitDisplayName}:</strong> {toPrecision((parent?.Scale?.Y ?? 0) * totalScale, 2)}
        </span>
        <span>
          <strong>Depth, {lengthUnitDisplayName}:</strong> {toPrecision((parent?.Scale?.Z ?? 0) * totalScale, 2)}
        </span>
      </div>
    )
  )
}

export default AIAnnotationEditorDimensions
