import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import _, { uniqueId } from 'lodash'

import { intersects } from '@tabeeb/modules/pointCloud/utils/shape'

import {
  addUniqueAiObjectConnection,
  deleteUniqueAiObjectConnection,
  getUniqueAiObjectsConnections,
} from '@tabeeb/modules/artificialIntelligence/actions'
import {
  getUniqueAIObjectsConnections,
  getSelectedPageUniqueAIObjectsWithAnnotations,
} from '@tabeeb/modules/artificialIntelligence/selectors'
import { onAddErrorNotification, onAddSuccessNotification } from '@tabeeb/modules/notification/actions'

import { callApiAsync } from '@tabeeb/shared/utils/requests'
import { UniqueAIObjectConnectionType } from '@tabeeb/enums'

const Mode = {
  View: 'view',
  Edit: 'edit',
}

const useUniqueAIObjectsHierarchy = () => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [mode, setMode] = useState(Mode.View)
  const editing = mode === Mode.Edit

  const objects = useSelector(getSelectedPageUniqueAIObjectsWithAnnotations)
  const uniqueAiObjectIds = useMemo(() => objects.map((a) => a.id), [objects])
  const connections = useSelector(getUniqueAIObjectsConnections)

  const [connectionsEditState, setConnectionsEditState] = useState(null)

  const displayConnections = useMemo(() => {
    if (editing) {
      return connectionsEditState
    }

    return connections
  }, [editing, connections, connectionsEditState])

  useEffect(() => {
    if (uniqueAiObjectIds.length > 0) {
      dispatch(getUniqueAiObjectsConnections.request({ uniqueAiObjectIds }))
    }
  }, [uniqueAiObjectIds, dispatch])

  const onAutolink = useCallback(() => {
    const calculatedConnections = []

    const visited = new Set()
    const orderedObjects = _.orderBy(objects, (object) => object.level, 'asc')
    const levels = _.groupBy(objects, (object) => object.level)

    const traverse = (currentObject) => {
      visited.add(currentObject.id)

      for (const nextLevelObject of levels[currentObject.level + 1] || []) {
        if (visited.has(nextLevelObject.id)) {
          continue
        }

        if (intersects(currentObject.shape, nextLevelObject.shape)) {
          calculatedConnections.push({
            Id: uniqueId('new_'),
            ParentId: currentObject.uniqueAiObject.Id,
            ChildId: nextLevelObject.uniqueAiObject.Id,
            Type: UniqueAIObjectConnectionType.Link,
          })

          traverse(nextLevelObject)
        }
      }
    }

    for (const object of orderedObjects) {
      if (visited.has(object.id)) {
        continue
      }

      traverse(object)
    }

    setConnectionsEditState(calculatedConnections)
    setMode(Mode.Edit)
  }, [objects])

  const onAddConnection = useCallback(({ parentId, childId }) => {
    setConnectionsEditState((prevConnections) => {
      const nextConnections = prevConnections.filter((c) => c.ChildId !== childId)
      nextConnections.push({
        Id: uniqueId('new_'),
        ChildId: childId,
        ParentId: parentId,
        Type: UniqueAIObjectConnectionType.Link,
      })

      return nextConnections
    })
  }, [])

  const onRemoveConnection = useCallback(({ parentId, childId }) => {
    setConnectionsEditState((prevConnections) => {
      return prevConnections.filter((c) => !(c.ChildId === childId && c.ParentId === parentId))
    })
  }, [])

  const onEdit = useCallback(() => {
    setConnectionsEditState(connections)
    setMode(Mode.Edit)
  }, [connections])

  const onCancelEdit = useCallback(() => {
    setMode(Mode.View)
    setConnectionsEditState(connections)
  }, [connections])

  const onSave = useCallback(async () => {
    try {
      setSaving(true)

      const prevConnections = connections
      const nextConnections = connectionsEditState

      const toDelete = prevConnections.filter(
        (prevConnection) =>
          !nextConnections.some(
            (nextConnection) =>
              nextConnection.ChildId === prevConnection.ChildId && nextConnection.ParentId === prevConnection.ParentId
          )
      )

      const toAdd = nextConnections.filter(
        (nextConnection) =>
          !prevConnections.some(
            (prevConnection) =>
              prevConnection.ChildId === nextConnection.ChildId && prevConnection.ParentId === nextConnection.ParentId
          )
      )

      for (const connection of toDelete) {
        // eslint-disable-next-line no-await-in-loop
        await callApiAsync(deleteUniqueAiObjectConnection.request({ uniqueAIObjectConnectionId: connection.Id }))
      }

      for (const connection of toAdd) {
        // eslint-disable-next-line no-await-in-loop
        await callApiAsync(addUniqueAiObjectConnection.request(connection))
      }

      dispatch(onAddSuccessNotification({ message: 'Hierarchy saved successfully' }))
    } catch (e) {
      dispatch(onAddErrorNotification({ message: 'Failed to save hierarchy' }))
    } finally {
      setSaving(false)
      setMode(Mode.View)
      setConnectionsEditState(null)
    }
  }, [connections, dispatch, connectionsEditState])

  const hierarchy = useMemo(() => {
    const hierarchyObjects = []

    const visited = new Set()

    const orderedObjects = _.orderBy(objects, (object) => object.level, 'asc')

    const build = (object) => {
      visited.add(object.id)

      const children = orderedObjects.filter((o) =>
        displayConnections.some((c) => c.ParentId === object.id && c.ChildId === o.id)
      )

      const childrenObjects = []
      for (const child of children) {
        if (visited.has(child.id)) {
          continue
        }

        childrenObjects.push(build(child))
      }

      return {
        ...object,
        childrenObjects,
      }
    }

    for (const object of orderedObjects) {
      if (visited.has(object.id)) {
        continue
      }

      hierarchyObjects.push(build(object))
    }

    return hierarchyObjects
  }, [displayConnections, objects])

  return {
    loading,
    saving,
    hierarchy,
    editing,
    objects,
    connections,
    onAutolink,
    onAddConnection,
    onRemoveConnection,
    onEdit,
    onCancelEdit,
    onSave,
  }
}

export default useUniqueAIObjectsHierarchy
