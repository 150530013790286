import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PageInfo } from '../../Info'
import { PageAnswersList } from '../../AnswersLists'
import DiscussionDialog from '../DiscussionDialog'

import * as discussionActions from '../../../actions'
import * as discussionSelectors from '../../../selectors'

const PageAnswersDiscussionDialog = () => {
  const dispatch = useDispatch()

  const open = useSelector(discussionSelectors.getPageAnswersDialogOpen)
  const page = useSelector(discussionSelectors.getDiscussionPage)

  const onClose = useCallback(() => {
    dispatch(discussionActions.closePageFormAnswersDialog())
  }, [])

  return (
    <DiscussionDialog
      open={open}
      onClose={onClose}
      header={<PageInfo page={page} />}
      content={<PageAnswersList pageId={page?.id} />}
    />
  )
}

PageAnswersDiscussionDialog.propTypes = {}

export default memo(PageAnswersDiscussionDialog)
