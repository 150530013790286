import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'

import { GraphElementType, UniqueAIObjectConnectionType } from '@tabeeb/enums'
import InlineSearch from '@tabeeb/modules/calendar/forms/shared/InlineSearch'
import AIObjectInfo, { AIObjectInfoSkeleton } from '@tabeeb/modules/modelTraining/components/AIObjectInfo'
import { NoAIObjectFoundPlaceholder } from '@tabeeb/modules/modelTraining/components/Placeholders'
import { getContentState } from '@tabeeb/modules/shared/content/selectors'
import { getSessions } from '@tabeeb/modules/sessionsPage/actions'
import { SessionInfo, SessionInfoSkeleton } from '@tabeeb/modules/calendar/components/Info'
import { NoSessionsFoundPlaceholder } from '@tabeeb/modules/calendar/components/Placeholders'
import { onAddErrorNotification, onAddWarningNotification } from '@tabeeb/modules/notification/actions'
import { closeEditUniqueAiObjectConnectionsDialog, searchUniqueAiObjects } from '../../actions'
import {
  getAIObjectById,
  getIsEditUniqueAIObjectConnectionsDialogOpen,
  getUniqueAIObjectIdForConnectionsEdit,
} from '../../selectors'
import { useUniqueAIObject } from '../../hooks'

import UniqueAIObjectConnectionsList from '../UniqueAIObjectConnectionsList'
import {
  UniqueAIObjectRoleInConnection,
  UniqueAIObjectRoleInConnectionDescription,
  UniqueAIObjectRoleInConnectionDisplayName,
} from '../../constants'

const EditUniqueAIObjectConnectionsDialog = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(getIsEditUniqueAIObjectConnectionsDialogOpen)
  const uniqueAIObjectId = useSelector(getUniqueAIObjectIdForConnectionsEdit)
  const currentContent = useSelector(getContentState)

  const [roleInConnection, setRoleInConnection] = useState(UniqueAIObjectRoleInConnection.Parent)
  const [isNewConnectionFormOpen, setIsNewConnectionFormOpen] = useState(false)
  const [selectedUniqueAIObject, setSelectedUniqueAIObject] = useState(null)
  const [selectedContent, setSelectedContent] = useState(null)

  const [showContentInlineSearch, setShowContentInlineSearch] = useState(false)
  const [showUniqueAIObjectInlineSearch, setShowUniqueAIObjectInlineSearch] = useState(false)

  useEffect(() => {
    if (currentContent) {
      setSelectedContent({ Id: currentContent.contentId, Description: currentContent.description })
    }
  }, [currentContent, currentContent.Description, currentContent.contentId])

  const { uniqueAIObject, loading, deleting, adding, onAddConnection, onDeleteConnection, onError } = useUniqueAIObject(
    {
      id: uniqueAIObjectId,
    }
  )

  const aiObject = useSelector((state) => getAIObjectById(state, { Id: uniqueAIObject?.AIObjectId }))

  const handleSelect = (value) => {
    setSelectedUniqueAIObject(value)
  }

  const handleChangeRoleInConnection = (e) => {
    setRoleInConnection(e.target.value)
    setSelectedUniqueAIObject(null)

    if (
      (e.target.value === UniqueAIObjectRoleInConnection.Parent ||
        e.target.value === UniqueAIObjectRoleInConnection.Child) &&
      selectedContent.Id !== currentContent.contentId
    ) {
      setSelectedContent({ Id: currentContent.contentId, Description: currentContent.description })
    }
  }

  const handleContentSelect = (value) => {
    setSelectedContent(value)
    setSelectedUniqueAIObject(null)
  }

  const onSearch = useCallback(
    ({ searchText, pageNumber, pageSize }) => {
      const data = {
        skip: (pageNumber - 1) * pageSize,
        take: pageSize,
        searchText,
        uniqueAIObjectId: uniqueAIObject.Id,
        contentId: selectedContent.Id || currentContent.contentId,
        type:
          roleInConnection === UniqueAIObjectRoleInConnection.Projection
            ? UniqueAIObjectConnectionType.Projection
            : UniqueAIObjectConnectionType.Link,
        isParent: roleInConnection === UniqueAIObjectRoleInConnection.Parent,
      }

      return searchUniqueAiObjects.request(data)
    },
    [uniqueAIObject?.Id, selectedContent?.Id, currentContent.contentId, roleInConnection]
  )

  const onContentSearch = useCallback(({ searchText, pageSize, pageNumber }) => {
    const filterModel = {
      Sorting: {
        Column: 'CreatedOn',
        Order: 'desc',
      },
      Filtering: {
        GeneralFilter: searchText,
      },
    }

    const data = {
      pageNumber,
      pageSize,
      allContents: true,
      allFolders: true,
      searchQuery: filterModel,
    }

    return getSessions.request(data)
  }, [])

  const onClose = () => {
    dispatch(closeEditUniqueAiObjectConnectionsDialog())

    setRoleInConnection(UniqueAIObjectRoleInConnection.Parent)
    setIsNewConnectionFormOpen(false)
    setSelectedUniqueAIObject(null)
    if (currentContent) {
      setSelectedContent({ Id: currentContent.contentId, Description: currentContent.description })
    }
  }

  const isProjectionConnectionValid = () => {
    if (roleInConnection === UniqueAIObjectRoleInConnection.Projection) {
      return selectedContent.Id !== currentContent.contentId
    }
    return true
  }

  const getProjectionConnectionErrorMessage = () => {
    return 'Projection connections are only allowed between objects from different sessions. Please select a different session.'
  }

  const handleAddConnection = () => {
    if (!isProjectionConnectionValid()) {
      dispatch(
        onAddErrorNotification({
          message: getProjectionConnectionErrorMessage(),
        })
      )
      return
    }

    const getParentId = () => {
      switch (roleInConnection) {
        case UniqueAIObjectRoleInConnection.Child:
          return selectedUniqueAIObject.Id
        case UniqueAIObjectRoleInConnection.Projection:
        case UniqueAIObjectRoleInConnection.Parent:
        default:
          return uniqueAIObjectId
      }
    }

    const getChildId = () => {
      switch (roleInConnection) {
        case UniqueAIObjectRoleInConnection.Child:
          return uniqueAIObjectId
        case UniqueAIObjectRoleInConnection.Projection:
        case UniqueAIObjectRoleInConnection.Parent:
        default:
          return selectedUniqueAIObject.Id
      }
    }

    const model = {
      ParentId: getParentId(),
      ChildId: getChildId(),
      Type:
        roleInConnection === UniqueAIObjectRoleInConnection.Projection
          ? UniqueAIObjectConnectionType.Projection
          : UniqueAIObjectConnectionType.Link,
    }

    onAddConnection(model)
  }

  const handleDeleteConnection = (uniqueAIObjectConnectionId) => {
    onDeleteConnection({ uniqueAIObjectConnectionId })
  }

  const handleContentNameClick = () => {
    if (roleInConnection === UniqueAIObjectRoleInConnection.Projection) {
      setShowContentInlineSearch((prevState) => !prevState)
    }
  }

  const handleUniqueAIObjectNameClick = () => {
    if (!isProjectionConnectionValid()) {
      dispatch(
        onAddWarningNotification({
          message: getProjectionConnectionErrorMessage(),
        })
      )
      return
    }
    setShowUniqueAIObjectInlineSearch((prevState) => !prevState)
  }

  const roleInConnectionOptions = useMemo(() => {
    const options = [
      {
        value: UniqueAIObjectRoleInConnection.Parent,
        name: UniqueAIObjectRoleInConnectionDisplayName[UniqueAIObjectRoleInConnection.Parent],
        description: UniqueAIObjectRoleInConnectionDescription[UniqueAIObjectRoleInConnection.Parent],
      },
    ]

    if (aiObject?.GraphElementType === GraphElementType.Node) {
      options.push({
        value: UniqueAIObjectRoleInConnection.Child,
        name: UniqueAIObjectRoleInConnectionDisplayName[UniqueAIObjectRoleInConnection.Child],
        description: UniqueAIObjectRoleInConnectionDescription[UniqueAIObjectRoleInConnection.Child],
      })
    }

    if (aiObject?.IsProjectionEnabled) {
      options.push({
        value: UniqueAIObjectRoleInConnection.Projection,
        name: UniqueAIObjectRoleInConnectionDisplayName[UniqueAIObjectRoleInConnection.Projection],
        description: UniqueAIObjectRoleInConnectionDescription[UniqueAIObjectRoleInConnection.Projection],
      })
    }

    return options
  }, [aiObject?.GraphElementType, aiObject?.IsProjectionEnabled])

  return (
    <Dialog open={isOpen} maxWidth='sm' fullWidth onClose={onClose}>
      <DialogTitle>Edit object connections</DialogTitle>
      <DialogContent>
        {loading ? (
          <LinearProgress />
        ) : (
          <>
            <TextField
              disabled
              fullWidth
              label='Object'
              variant='outlined'
              value={uniqueAIObject ? uniqueAIObject.Name : ''}
              InputProps={{
                readOnly: true,
              }}
              sx={{ marginBottom: 1 }}
            />
            <Button
              fullWidth
              variant='contained'
              color='primary'
              onClick={() => setIsNewConnectionFormOpen((prevState) => !prevState)}
            >
              {isNewConnectionFormOpen ? 'Cancel' : 'Add New Connection'}
            </Button>
            <Collapse in={isNewConnectionFormOpen}>
              <Box mt={2}>
                <Typography variant='subtitle2' gutterBottom>
                  New Connection
                </Typography>
                <Box display='flex' flexDirection='column' gap={1}>
                  <FormControl fullWidth variant='outlined' margin='normal'>
                    <InputLabel id='relations-type-select-label'>Role in connection</InputLabel>
                    <Select
                      labelId='relations-type-select-label'
                      label='Select relations type'
                      variant='outlined'
                      value={roleInConnection}
                      renderValue={(value) => (
                        <Typography variant='body1'>{UniqueAIObjectRoleInConnectionDisplayName[value]}</Typography>
                      )}
                      onChange={handleChangeRoleInConnection}
                    >
                      {roleInConnectionOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          <Box display='flex' flexDirection='column'>
                            <Typography variant='body1'>{option.name}</Typography>
                            <Typography variant='body2' color='textSecondary'>
                              {option.description}
                            </Typography>
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {!showContentInlineSearch && (
                    <TextField
                      disabled={roleInConnection !== UniqueAIObjectRoleInConnection.Projection}
                      fullWidth
                      label='Session'
                      variant='outlined'
                      onClick={handleContentNameClick}
                      value={selectedContent ? selectedContent.Description : ''}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{ marginBottom: 1 }}
                    />
                  )}
                  {showContentInlineSearch && (
                    <InlineSearch
                      onSelect={handleContentSelect}
                      onSearch={onContentSearch}
                      onClose={handleContentNameClick}
                      components={{
                        info: SessionInfo,
                        skeleton: SessionInfoSkeleton,
                        placeholder: NoSessionsFoundPlaceholder,
                      }}
                      infinite
                    />
                  )}

                  {selectedContent && (
                    <>
                      {!showUniqueAIObjectInlineSearch && (
                        <TextField
                          fullWidth
                          label='Object for connection'
                          variant='outlined'
                          onClick={handleUniqueAIObjectNameClick}
                          value={selectedUniqueAIObject ? selectedUniqueAIObject.Name : ''}
                          InputProps={{
                            readOnly: true,
                          }}
                          sx={{ marginBottom: 1 }}
                        />
                      )}
                      {showUniqueAIObjectInlineSearch && (
                        <InlineSearch
                          onSelect={handleSelect}
                          onSearch={onSearch}
                          onClose={handleUniqueAIObjectNameClick}
                          components={{
                            info: AIObjectInfo,
                            skeleton: AIObjectInfoSkeleton,
                            placeholder: NoAIObjectFoundPlaceholder,
                          }}
                          infinite
                        />
                      )}
                    </>
                  )}
                  <Button
                    variant='contained'
                    disabled={!selectedUniqueAIObject || adding}
                    color='primary'
                    onClick={handleAddConnection}
                    style={{ marginTop: 2 }}
                    startIcon={adding ? <CircularProgress size='1.11em' /> : null}
                  >
                    Add Connection
                  </Button>
                </Box>
              </Box>
              <Divider style={{ marginTop: '20px' }} />
            </Collapse>
            <Typography style={{ marginTop: '20px' }} variant='subtitle2'>
              Parents
            </Typography>
            <UniqueAIObjectConnectionsList
              connections={uniqueAIObject.ParentConnections.filter(
                (connection) => connection.Type !== UniqueAIObjectConnectionType.Projection
              )}
              onDelete={handleDeleteConnection}
              deleting={deleting}
              variant='parents'
            />
            <Typography variant='subtitle2'>Children</Typography>
            <UniqueAIObjectConnectionsList
              connections={uniqueAIObject.ChildConnections.filter(
                (connection) => connection.Type !== UniqueAIObjectConnectionType.Projection
              )}
              onDelete={handleDeleteConnection}
              deleting={deleting}
              variant='children'
            />
            {aiObject?.GraphElementType === GraphElementType.Edge && (
              <>
                <Typography variant='subtitle2'>Projections</Typography>
                <UniqueAIObjectConnectionsList
                  connections={uniqueAIObject.ChildConnections.filter(
                    (connection) => connection.Type === UniqueAIObjectConnectionType.Projection
                  )}
                  onDelete={handleDeleteConnection}
                  deleting={deleting}
                  variant='children'
                />
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant='outlined'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default memo(EditUniqueAIObjectConnectionsDialog)
