import { combineReducers } from 'redux'

import aiObjects from './aiObjects'
import aiObjectsForTags from './aiObjectsForTags'
import aiObjectsWithStatistics from './aiObjectsWithStatistics'
import attachAIModel from './attachAIModel'
import counterValuesList from './counterValuesList'
import deleteAiClass from './deleteAiClass'
import deleteNLPModel from './deleteNLPModel'
import deletePredictionSet from './deletePredictionSet'
import deleteVQAModel from './deleteVQAModel'
import editAIClassDialog from './editAIClassDialog'
import isAIObjectsLoading from './isAIObjectsLoading'
import isNLPModelsLoading from './isNLPModelsLoading'
import isNlpTagsWithAIObjectsLoading from './isNlpTagsWithAIObjectsLoading'
import isPredictionSetsLoading from './isPredictionSetsLoading'
import isTagUpdating from './isTagUpdating'
import isTenantConfigUpdating from './isTenantConfigUpdating'
import isUnattachedAIModelsLoading from './isUnattachedAIModelsLoading'
import isVQAModelsLoading from './isVQAModelsLoading'
import loadingCounters from './loadingCounters'
import nlpModels from './nlpModels'
import nlpTagsWithAIObjects from './nlpTagsWithAIObjects'
import predictionSets from './predictionSets'
import selectedAIObject from './selectedAIObject'
import selectedTagForAIObjectAttach from './selectedTagForAIObjectAttach'
import selectedUniqueAIObject from './selectedUniqueAIObject'
import selectedUniqueAIObjectAnnotation from './selectedUniqueAIObjectAnnotation'
import unattachedAIModels from './unattachedAIModels'
import uniqueAIObjects from './uniqueAIObjects'
import uniqueAIObjectsConnections from './uniqueAIObjectsConnections'
import vqaModels from './vqaModels'
import conflictingAIObjects from './conflictingAIObjects'
import editUniqueAIObjectConnectionsDialog from './editUniqueAIObjectConnectionsDialog'

export default combineReducers({
  aiObjects,
  aiObjectsForTags,
  aiObjectsWithStatistics,
  attachAIModel,
  conflictingAIObjects,
  counterValuesList,
  deleteAiClass,
  deleteNLPModel,
  deletePredictionSet,
  deleteVQAModel,
  editAIClassDialog,
  editUniqueAIObjectConnectionsDialog,
  isAIObjectsLoading,
  isNLPModelsLoading,
  isNlpTagsWithAIObjectsLoading,
  isPredictionSetsLoading,
  isTagUpdating,
  isTenantConfigUpdating,
  isUnattachedAIModelsLoading,
  isVQAModelsLoading,
  loadingCounters,
  nlpModels,
  nlpTagsWithAIObjects,
  predictionSets,
  selectedAIObject,
  selectedTagForAIObjectAttach,
  selectedUniqueAIObject,
  selectedUniqueAIObjectAnnotation,
  unattachedAIModels,
  uniqueAIObjects,
  uniqueAIObjectsConnections,
  vqaModels,
})
