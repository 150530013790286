import { memo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { isUndefined } from 'lodash'

import { Close } from '@material-ui/icons'
import { IconButton, Dialog, Divider, withStyles } from '@material-ui/core'

import { MIN_DIALOG_SIZE, DIALOG_DEFAULT_SIZE } from '../../../constants'

import Resizable from '../Resizable'

import styles from './styles'

const DiscussionDialog = ({
  classes,
  header,
  content,
  open,
  onClose,
  minDialogSize,
  defaultDialogSize,
  defaultPosition,
  allowBackgroundInteraction,
}) => {
  const [{ width, height }, setSize] = useState(defaultDialogSize)
  const [{ x, y }, setPosition] = useState({ x: defaultPosition.x, y: defaultPosition.y })

  const onResize = useCallback(
    ({ width, height }) => {
      const widthLeft = window.innerWidth - (x || (window.innerWidth - width) / 2)
      const heightLeft = window.innerHeight - (y || (window.innerHeight - height) / 2)

      setSize({
        width: Math.min(Math.max(minDialogSize.width, width), widthLeft),
        height: Math.min(Math.max(minDialogSize.height, height), heightLeft),
      })
    },
    [x, y, minDialogSize]
  )

  const onDrag = useCallback(
    ({ x, y }) => {
      const widthLeft = window.innerWidth - width
      const heightLeft = window.innerHeight - height

      setPosition({
        x: Math.min(Math.max(0, x), widthLeft),
        y: Math.min(Math.max(0, y), heightLeft),
      })
    },
    [width, height]
  )

  const backgroundInteractionProps = {
    disableEnforceFocus: allowBackgroundInteraction,
    style: { position: allowBackgroundInteraction ? 'initial' : 'fixed' },
  }

  return (
    <Dialog
      {...backgroundInteractionProps}
      open={open}
      onClose={onClose}
      BackdropProps={{
        className: classes.backdrop,
      }}
      PaperProps={{
        className: classes.paper,
        style: {
          top: isUndefined(y) ? '50%' : y,
          left: isUndefined(x) ? '50%' : x,
          transform: `translate(${isUndefined(x) ? '-50%' : 0}, ${isUndefined(y) ? '-50%' : 0})`,
          width,
          height,
          zIndex: 1200,
        },
      }}
      fullWidth
    >
      <Resizable width={width} height={height} onResize={onResize} onDrag={onDrag}>
        <div className={classes.header}>
          {header}
          <IconButton className={classes.button} onClick={onClose} title='Close'>
            <Close />
          </IconButton>
        </div>
        <Divider />
        <div className={classes.content}>{content}</div>
      </Resizable>
    </Dialog>
  )
}

DiscussionDialog.defaultProps = {
  minDialogSize: MIN_DIALOG_SIZE,
  defaultDialogSize: DIALOG_DEFAULT_SIZE,
  defaultPosition: { x: undefined, y: undefined },
}

DiscussionDialog.propTypes = {
  classes: PropTypes.shape({
    paper: PropTypes.string.isRequired,
    backdrop: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
  minDialogSize: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  defaultDialogSize: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  open: PropTypes.bool.isRequired,
  content: PropTypes.element.isRequired,
  header: PropTypes.element.isRequired,
  onClose: PropTypes.func.isRequired,
  allowBackgroundInteraction: PropTypes.bool,
}

export default memo(withStyles(styles)(DiscussionDialog))
