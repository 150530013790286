import { takeLatest, all, put } from 'redux-saga/effects'

import * as aiActions from '../actions'
import * as notificationActions from '../../notification/actions'

function* onGetUniqueAiObjectsFailed(action) {
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to load objects.' }))
}

function* onGetUniqueAiObjectForEditFailed(action) {
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to load the object.' }))
}

function* getUniqueAIObjects() {
  yield all([
    takeLatest(aiActions.getUniqueAiObjects.failed, onGetUniqueAiObjectsFailed),
    takeLatest(aiActions.getUniqueAiObjectForEditFailed, onGetUniqueAiObjectForEditFailed),
  ])
}

export default getUniqueAIObjects
